<template>
  <ReDocWrapper
    :specOrSpecUrl="`${origin}/${$route.params.domain}/${$route.params.module}`"
  />
</template>

<script>
import ReDocWrapper from "../components/ReDocWrapper";

export default {
  components: {
    ReDocWrapper,
  },
  data() {
    return {
      origin: "https://apicenter.blob.core.windows.net",
    };
  },
};
</script>
