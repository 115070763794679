<template>
  <div id="redoc-container"></div>
</template>

<script>
import * as redoc from "redoc/bundles/redoc.standalone";

export default {
  props: ["specOrSpecUrl", "options"],
  data() {
    return {
      options_: {
        scrollYOffset: 50,
        hideDownloadButton: true,
      },
    };
  },
  created() {
    if (this.options) {
      this.options_ = Object.assign(this.options_, this.options);
    }
  },
  mounted() {
    redoc.init(
      this.specOrSpecUrl,
      this.options_,
      document.getElementById("redoc-container")
    );
  },
};
</script>

<style lang="scss">
.react-tabs__tab-panel pre {
  background-color: rgb(17, 23, 26) !important;
  color: rgb(255, 255, 255) !important;
}
.menu-content {
  top: 0 !important;
  height: 100vh !important;
}
</style>
